"use strict";

import "regulus-oscar";
import "components/menu";
import "components/catalogue_filters";

$(function () {
  $('.slider').each(function (_, element) {
    const slider = $(element);
    const sliders = slider.find('.slider-container');

    slider.find('.previous').click(function () {
        for (let i = 0; i < sliders.length; i++) {
            sliders[i].scrollBy({
              top: 0,
              left: -275,
              behavior: 'smooth'
            });
        }
    });

    slider.find('.next').click(function () {
        for (let i = 0; i < sliders.length; i++) {
            sliders[i].scrollBy({
              top: 0,
              left: 275,
              behavior: 'smooth'
            });
        }
    });

  });
});
